export const GET_CONTRACTS_STATUS_COUNT_REQUEST = "GET_CONTRACTS_STATUS_COUNT_REQUEST";
export const GET_CONTRACTS_STATUS_COUNT = "GET_CONTRACTS_STATUS_COUNT";

export const GET_CONTRACTS_LIST_REQUEST = "GET_CONTRACTS_LIST_REQUEST";
export const GET_CONTRACTS_LIST = "GET_CONTRACTS_LIST";

export const GET_CONTRACT_STATUS_REQUEST = "GET_CONTRACT_STATUS_REQUEST";
export const GET_CONTRACT_STATUS = "GET_CONTRACT_STATUS";

export const SIGN_CONTRACT_REQUEST = "SIGN_CONTRACT_REQUEST";
export const SIGN_CONTRACT = "SIGN_CONTRACT";

export const CONTRACT_TYPE_REQUEST = "CONTRACT_TYPE_REQUEST";
export const CONTRACT_TYPE = "CONTRACT_TYPE";

export const CONTRACT_CATEGORY_REQUEST = "CONTRACT_CATEGORY_REQUEST";
export const CONTRACT_CATEGORY = "CONTRACT_CATEGORY";

export const CAR_BRAND_REQUEST = "CAR_BRAND_REQUEST";
export const CAR_BRAND = "CAR_BRAND";

export const VEHICLE_LICENSE_REQUEST = "VEHICLE_LICENSE_REQUEST";
export const VEHICLE_LICENSE = "VEHICLE_LICENSE";

export const GET_IDENTIFICATION_TYPES_REQUEST ='GET_IDENTIFICATION_TYPES_REQUEST';
export const GET_IDENTIFICATION_TYPES ='GET_IDENTIFICATION_TYPES';

export const SEARCH_BY_IDENTIFICATION_REQUEST ='SEARCH_BY_IDENTIFICATION_REQUEST';
export const SEARCH_BY_IDENTIFICATION ='SEARCH_BY_IDENTIFICATION';

export const CREATE_CONTRACT_REQUEST = "CREATE_CONTRACT_REQUEST";
export const CREATE_CONTRACT = "CREATE_CONTRACT";

export const SAVE_CONTRACT_AS_DRAFT_REQUEST = 'SAVE_CONTRACT_AS_DRAFT_REQUEST';
export const SAVE_CONTRACT_AS_DRAFT = 'SAVE_CONTRACT_AS_DRAFT';
