import { Redirect } from "react-router-dom";
import * as LazyComponent from "../../utils/LazyLoaded";
import { roles } from "../../utils/Constants";
import SuperAdminRoute from "./SuperAdminRoute";
import FirmUsersRoute from "./FirmUsersRoute";
import LandingPageRoute from "./LandingPageRoute";
export const generateRoutes = (role) => {
  const userInfo =  JSON.parse(localStorage.getItem('userInfo')) || null
  if (role) {

    if(role ===roles.LAWYER){
      
      return[     {
        kind: SuperAdminRoute,
        path: "/speed-contracts",
        component: LazyComponent.SpeedContract,
      },
      {
        kind: SuperAdminRoute,
        path: "/speed-contracts/:id",
        component: LazyComponent.ContractDetails,
      },
      {
        kind: SuperAdminRoute,
        path: "/speed-contracts/edit/:id",
        component: LazyComponent.EditSpeedContract,
      },]
    }
    else if (role == roles.FIRM_MANAGER || role == roles.SECRETARY) {
      return [
        {
          kind: FirmUsersRoute,
          path: "/cases/blankPage",
          component: LazyComponent.BlankPage,
        },
        {
          kind: FirmUsersRoute,
          path: "/dashboard",
          component: LazyComponent.Dashboard,
        },
        {
          kind: FirmUsersRoute,
          path: "/dashboard/allTasks",
          component: LazyComponent.AllTasks,
        },
        {
          kind: FirmUsersRoute,
          path: "/cases/addCase",
          component: LazyComponent.AddCase,
        },
        {
          kind: FirmUsersRoute,
          path: "/cases/edit/:id",
          component: LazyComponent.AddCase,
        },
        {
          kind: FirmUsersRoute,
          path: "/cases/:id",
          component: LazyComponent.CaseInfo,
        },
        // {
        //   kind: FirmUsersRoute,
        //   path: "/users",
        //   component: LazyComponent.Users,
        // },
        {
          kind: FirmUsersRoute,
          path: "/invoices",
          component: LazyComponent.Invoices,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices/create",
          component: LazyComponent.CreateInvoice,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices/edit/:id",
          component: LazyComponent.CreateInvoice,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices/show/:id",
          component: LazyComponent.ShowInvoice,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices/returnedList/:id",
          component: LazyComponent.ReturnInvoiceList,
        },
        {
          kind: FirmUsersRoute,
          path: "/firmSettings",
          component: LazyComponent.FirmSettings,
        },
        {
          kind: FirmUsersRoute,
          path: "/settings",
          component: LazyComponent.Settings,
        },
        {
          kind: FirmUsersRoute,
          path: "/legalLibrary",
          component: LazyComponent.LegalLibrary,
        },
        {
          kind: FirmUsersRoute,
          path: "/clients",
          component: LazyComponent.Clients,
        },
        {
          kind: FirmUsersRoute,
          path: "/clients/add",
          component: LazyComponent.AddClient,
        },
        {
          kind: FirmUsersRoute,
          path: "/clients/:id",
          component: LazyComponent.ClientDetails,
        },
        {
          kind: FirmUsersRoute,
          path: "/clients/edit/:id",
          component: LazyComponent.AddClient,
        },
        {
          kind: FirmUsersRoute,
          path: "/sheetInstructions",
          component: LazyComponent.FormattedSheetInstructions,
        },
        {
          kind: LandingPageRoute,
          path: "/",
          component: LazyComponent.landingPage,
        },
        {
          kind: Redirect,
          path: "**",
          to: "/",
        },
      ];
    } else if (role == roles.CASES_ADMINISTRATOR || role == roles.CONSULTANT || role == roles.LEGAL_PRACTITIONER || role == roles.LAWYER) {
      return [
        {
          kind: FirmUsersRoute,
          path: "/dashboard",
          component: LazyComponent.Dashboard,
        },
        {
          kind: FirmUsersRoute,
          path: "/dashboard/allTasks",
          component: LazyComponent.AllTasks,
        },
        {
          kind: FirmUsersRoute,
          path: "/cases/addCase",
          component: LazyComponent.AddCase,
        },
        {
          kind: FirmUsersRoute,
          path: "/clients/add",
          component: LazyComponent.AddClient,
        },
        {
          kind: FirmUsersRoute,
          path: "/clients/edit/:id",
          component: LazyComponent.AddClient,
        },
        {
          kind: FirmUsersRoute,
          path: "/cases/edit/:id",
          component: LazyComponent.AddCase,
        },
        {
          kind: FirmUsersRoute,
          path: "/cases/:id",
          component: LazyComponent.CaseInfo,
        },
        {
          kind: FirmUsersRoute,
          path: "/settings",
          component: LazyComponent.Settings,
        },  
        {
          kind: FirmUsersRoute,
          path: "/legalLibrary",
          component: LazyComponent.LegalLibrary,
        },
        {
          kind: FirmUsersRoute,
          path: "/sheetInstructions",
          component: LazyComponent.FormattedSheetInstructions,
        },
        {
          kind: LandingPageRoute,
          path: "/",
          component: LazyComponent.landingPage,
        },
        {
          kind: Redirect,
          path: "**",
          to: "/cases",
        },
      ];
    }
    //  else if (role == roles.LEGAL_PRACTITIONER) {
    //   return [
    //     {
    //       kind: FirmUsersRoute,
    //       path: "/cases/:id",
    //       component: LazyComponent.CaseInfo,
    //     },
    //     {
    //       kind: FirmUsersRoute,
    //       path: "/legalLibrary",
    //       component: LazyComponent.LegalLibrary,
    //     },
    //     {
    //       kind: Redirect,
    //       path: "**",
    //       to: "/cases",
    //     },
    //   ];
    // }
  
    else if (role[0]===roles.SUPERVISOR ) { 
     
      return[     {
        kind: SuperAdminRoute,
        path: "/speed-contracts",
        component: LazyComponent.SpeedContract,
      },
      {
        kind: SuperAdminRoute,
        path: "/speed-contracts/:id",
        component: LazyComponent.ContractDetails,
      },
      {
        kind: SuperAdminRoute,
        path: "/speed-contracts/edit/:id",
        component: LazyComponent.EditSpeedContract,
      },]
      }
     else if (role == roles.SUPER_ADMIN ||role==roles.ADMIN) {
    
      return [
        {
          kind: SuperAdminRoute,
          path: "/addFirm",
          component: LazyComponent.AddFirm,
        },
        {
          kind: SuperAdminRoute,
          path: "/dashboard",
          component: LazyComponent.FirmsList,
        },
        {
          kind: SuperAdminRoute,
          path: "/speed-contracts",
          component: LazyComponent.SpeedContract,
        },
        {
          kind: SuperAdminRoute,
          path: "/speed-contracts/:id",
          component: LazyComponent.ContractDetails,
        },
        {
          kind: SuperAdminRoute,
          path: "/speed-contracts/edit/:id",
          component: LazyComponent.EditSpeedContract,
        },
        {
          kind: SuperAdminRoute,
          path: "/users",
          component: LazyComponent.UsersManagement,
        },
        {
          kind: Redirect,
          path: "**",
          to: "/",
        },
      ];
    } else if (role == roles.FINANCE_SPECIALIST) {
      return [
        {
          kind: FirmUsersRoute,
          path: "/dashboard",
          component: LazyComponent.Dashboard,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices",
          component: LazyComponent.Invoices,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices/create",
          component: LazyComponent.CreateInvoice,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices/edit/:id",
          component: LazyComponent.CreateInvoice,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices/show/:id",
          component: LazyComponent.ShowInvoice,
        },
        {
          kind: FirmUsersRoute,
          path: "/invoices/returnedList/:id",
          component: LazyComponent.ReturnInvoiceList,
        },
        {
          kind: Redirect,
          path: "**",
          to: "/invoices",
        },
      ];
    }
  } else {
    return (userInfo &&  !userInfo?.role) ? [
      {
        kind: Redirect,
        path: "**",
        to: "/contracts",
      },
    ] :
    [
      {
        kind: Redirect,
        path: "**",
        to: "/",
      },
    ];
  }
};
