import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
export const legal_email = "info@mustand.com";
export const legal_phone = "+966 55 314 2990";
export const blog1_publish_date = 'يوليو 2024';
export const blog2_publish_date = 'يوليو 2024';
export const socialNetworks = [
  {
    title: (
      <TwitterIcon
        color="primary"
        classes={{
          colorPrimary: "twitter-icon",
        }}
      />
    ),
    id: "twitter",
  },
  {
    title: (
      <FacebookIcon
        color="primary"
        classes={{
          colorPrimary: "facebook-icon",
        }}
      />
    ),
    id: "facebook",
  },
];
export const identityTypes = [
  {
    title: "رقم الإقامة",
    id: "residency",
  },
  {
    title: "الهوية الوطنية",
    id: "identity",
  },
  {
    title: "جواز السفر",
    id: "passport",
  },
];
export const caseStatusList = [
  {
    title: "جديدة",
    id: "new",
  },
  {
    title: "منقولة",
    id: "transferred",
  },
  {
    title: "جارية",
    id: "inprogress",
  },
  {
    title: "مقفلة",
    id: "closed",
  },
];
export const hijriMonths = [
  "محرم",
  "صفر",
  "ربيع الأول",
  "ربيع الآخر",
  "جمادى الأولى",
  "جمادى الآخرة",
  "رجب",
  "شعبان",
  "رمضان",
  "شوال",
  " ذو القعدة",
  " ذو الحجة ",
];
export const arabicMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "ابريل",
  "مايو",
  "يونيو",
  "يوليو",
  "اغسطس",
  "سبتمبر",
  "اكتوبر",
  "نوفمبر",
  "ديسمبر",
];
export const arabicDays = [
  "الاحد",
  "الاثنين",
  "الثلاثاء",
  "الاربعاء",
  "الخميس",
  "الجمعة",
  "السبت",
];

export const roles = {
  SUPER_ADMIN: "Super Admin",
  FIRM_MANAGER: "Firm Manager",
  SECRETARY: "Secretary",
  CONSULTANT: "Consultant", // مستشار قانونى
  LAWYER: "Lawyer", //محامى
  LEGAL_PRACTITIONER: "Coordinator", // مساعد قانونى
  CASES_ADMINISTRATOR: "Case Manager", // مدير القضية
  FINANCE_SPECIALIST: "Accountant",
  CLIENT: "Client",
  ADMIN:"Admin",
  SUPERVISOR:"Supervisor"
};

export const allPermissions = {
  //USERS
  CREATE_USER: "user.create",
  UPDATE_USER: "user.update",
  DELETE_USER: "user.delete",
  SHOW_USER: "user.read",
  SUSPEND_USER: "user.suspend",
  REACTIVATE_USER: "user.reactivate",
  //CASES
  CREATE_CASE: "case.create",
  UPDATE_CASE: "case.update",
  DELETE_CASE: "case.delete",
  SHOW_CASE: "case.read",
  SHOW_CASES: "case.read_all",
  EXPORT_CASES: "export.cases",
  //CASES //MEMBERS
  CREATE_MEMBER: "member.create",
  UPDATE_MEMBER: "member.update",
  UNASSIGN_MEMBER: "member.delete",
  SHOW_MEMBER: "member.read",
  RESTORE_MEMBER: "member.restore",
  //CONTACTS
  CREATE_CONTACT: "contact.create",
  UPDATE_CONTACT: "contact.update",
  UNASSIGN_CONTACT: "contact.delete",
  SHOW_CONTACT: "contact.read",
  //TASKS
  CREATE_TASK: "task.create",
  UPDATE_TASK: "task.update",
  DELETE_TASK: "task.delete",
  SHOW_TASK: "task.read",
  SHOW_TASKS: "task.read_all",
  COMPLETE_TASK: "task.complete",
  COMPLETE_ANY_TASK: "task.complete_any",
  CREATE_REMINDER_TASK: "reminder.task_create",
  //EVENTS
  CREATE_EVENT: "event.create",
  UPDATE_EVENT: "event.update",
  DELETE_EVENT: "event.delete",
  SHOW_EVENT: "event.read",
  //HISTORY
  CREATE_HISTORY: "history.create",
  UPDATE_HISTORY: "history.update",
  DELETE_HISTORY: "history.delete",
  SHOW_HISTORY: "history.read",
  //DECISIONS
  CREATE_DECISION: "decision.create",
  UPDATE_DECISION: "decision.update",
  DELETE_DECISION: "decision.delete",
  SHOW_DECISION: "decision.read",
  //PARTIES
  CREATE_PARTY: "party.create",
  UPDATE_PARTY: "party.update",
  DELETE_PARTY: "party.delete",
  SHOW_PARTY: "party.read",
  //SESSIONS
  CREATE_SESSION: "session.create",
  UPDATE_SESSION: "session.update",
  DELETE_SESSION: "session.delete",
  SHOW_SESSION: "session.read",
  CREATE_REMINDER_SESSION: "reminder.session_create",
  //SESSION OUTCOME
  CREATE_OUTCOME: "outcome.create",
  UPDATE_OUTCOME: "outcome.update",
  DELETE_OUTCOME: "outcome.delete",
  SHOW_OUTCOME: "outcome.read",
  //SESSION_REPORT
  CREATE_SESSION_REPORT: "session_report.create",
  UPDATE_SESSION_REPORT: "session_report.update",
  SIGN_SESSION_REPORT: "session_report.sign", // approve report
  DOWNLOAD_SESSION_REPORT: "session_report.download",
  READ_SESSION_REPORT: "session_report.read", // view report
  //VERDICTS
  CREATE_VERDICT: "verdict.create",
  UPDATE_VERDICT: "verdict.update",
  DELETE_VERDICT: "verdict.delete",
  SHOW_VERDICT: "verdict.read",
  //REALATED_CASE
  CREATE_RELATED_CASE: "related_case.create",
  DELETE_RELATED_CASE: "related_case.delete",
  //ATTACHMENTS
  CREATE_ATTACHMENTS: "attachment.create",
  DELETE_ATTACHMENTS: "attachment.delete",
  SHOW_ATTACHMENTS: "attachment.read",
  //CLIENTS
  CREATE_CLIENT: "client.create",
  UPDATE_CLIENT: "client.update",
  DELETE_CLIENT: "client.delete",
  SHOW_CLIENT: "client.read",
  //RELATIONS
  CREATE_RELATION: "relation.create",
  UPDATE_RELATION: "relation.update",
  DELETE_RELATION: "relation.delete",
  SHOW_RELATION: "relation.read",
  //COLLABORATIONS
  CREATE_COLABORATIONS: "collaboration.create",
  UPDATE_COLABORATIONS: "collaboration.update",
  DELETE_COLABORATIONS: "collaboration.delete",
  SHOW_COLABORATIONS: "collaboration.read",
  //INVOICES
  CREATE_INVOICE: "invoice.create",
  UPDATE_INVOICE: "invoice.update",
  DELETE_INVOICE: "invoice.delete",
  SHOW_INVOICE: "invoice.read",
  //APPOINTMENT
  CREATE_APPOINTMENT: "appointment.create",
  UPDATE_APPOINTMENT: "appointment.update",
  SHOW_APPOINTMENT: "appointment.read",
  DELETE_APPOINTMENT: "appointment.delete",
  CREATE_ANY_APPOINTMENT: "appointment.update_any",
  //FIRM_SETTING:
  EDIT_FIRM_SETTING: "firm.edit_settings",
  SHOW_FIRM_SETTING: "firm.read_settings",
  //LAW_LIBRARY
  SHOW_LAW_LIBRARY: "law_library.read",
  //DASHBOARD
  DASHBOARD_VIEW_DUE_INVOICES: "dashboard.view_due_invoices",
  DASHBOARD_VIEW_TASKS: "dashboard.view_tasks",
  DASHBOARD_VIEW_CALENDAR: "dashboard.view_calendar",
  DASHBOARD_VIEW_COMMENTS: "dashboard.view_comments",
  DASHBOARD_LAST_OPENED: "dashboard.last_opened",
  DASHBOARD_VIEW_MAIN_MATRICS: 'dashboard.view_main_metrices',
  DASHBOARD_VIEW_CASE_MATRICS: 'dashboard.view_case_metrices',
  DASHBOARD_VIEW_VERDICT_MATRICS: 'dashboard.view_verdict_metrices',
  DASHBOARD_VIEW_CLIENT_MATRICS: 'dashboard.view_client_metrices',
  DASHBOARD_VIEW_TASK_MATRICS: 'dashboard.view_task_metrices',
  //ACTIVITY_LOGS
  VIEW_ACTIVITY_LOGS: 'logger.list',

  // users 
  CREATE_FIRM:"firm.create",
  CREATE_ADMIN:"admin.create",
  VIEW_ALL_ADMIN:"admin.view_all",
  UPDATE_ADMIN:"admin.update",
  VIEW_ADMIN:"admin.view",
  CREATE_CONTRACT:"contract.create",
  VIEW_ALL_CONTRACT:"contract.view_all",
  VIEW_LIST_CONTRACT:"contract.view_list",
  SUBMIT_CONTRACT:"contract.submit",
  VIEW_CONTRACT:"contract.view",
  ASSIGN_CONTRACT:"contract.assign"

};

export const userStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  SUSPENDED: "suspended",
  EXPIRED: "expired",
};

export const usersFilter = {
  ALL: "all",
  CURRENT: "current",
  REMOVED: "removed",
};

export const tasksFilter = {
  ALL: "all",
  CURRENT: "CURRENT",
  COMPLETED: "completed",
  OVERDUE: "overdue",
};

export const eventsStatusFilter = {
  ALL: "all",
  UPCOMING: "upcoming",
  CURRENT: "current",
  ENDED: "ended",
};

export const eventsAssigneeFilter = {
  ALL: "all",
  MY: "my",
};
export const sort = [
  {
    title: "من الأحدث إلى الأقدم ",
    id: "-start_date",
  },
  {
    title: "من الأقدم إلى الأحدث",
    id: "start_date",
  },
];
export const notesSort = [
  {
    label: "من الأحدث إلى الأقدم ",
    value: "-created_at",
  },
  {
    label: "من الأقدم إلى الأحدث",
    value: "created_at",
  },
];
export const attachSort = [
  {
    label: "الاحدث أولا",
    value: "new",
    key: 'updated_at',
  },
  {
    label: "الأقدم أولا",
    value: "old",
    key: '-updated_at',
  },
];
export const notesFilter = [
  { label: "الكل", value: "all" },
  { label: "رسائل للعميل و للفريق", value: "clients" },
  { label: "رسائل للفريق فقط", value: "members" },
  { label: "رسائل لها ردود", value: "has_replies" },
];

export const calendarEvents = {
  all: "all",
  invoice: "invoice",
  session: "session",
  task: "task",
  appointment: "appointment",
};
export const notificationsTypes = {
  ASSIGNED_TO_TASK: "App\\Notifications\\Tenant\\TaskAssigneeNotification",
  ASSIGNED_TO_EVENT: "App\\Notifications\\Tenant\\EventAssigneeNotification",
  ASSIGNED_TO_CASE:
    "App\\Notifications\\Tenant\\AssignMemberToCaseNotification",
  REMINDER_TO_TASK: "App\\Notifications\\Tenant\\TaskReminderNotification",
  REMINDER_TO_EVENT: "App\\Notifications\\Tenant\\EventReminderNotification",
  REMOVE_TASK_ASSIGNEE:
    "App\\Notifications\\Tenant\\TaskRemoveAssigneeNotification",
  REMOVE_EVENT_ASSIGNEE:
    "App\\Notifications\\Tenant\\EventRemoveAssigneeNotification",
  REMOVE_MEMBER_FROM_CASE:
    "App\\Notifications\\Tenant\\RemoveMemberFromCaseNotification",
  UPDATE_TASK: "App\\Notifications\\Tenant\\TaskUpdateNotification",
  UPDATE_EVENT: "App\\Notifications\\Tenant\\EventUpdateNotification",
  DELETE_TASK: "App\\Notifications\\Tenant\\TaskDeleteNotification",
  DELETE_EVENT: "App\\Notifications\\Tenant\\EventDeleteNotification",
  DELETE_CASE: "App\\Notifications\\Tenant\\CaseDeleteNotification",
  ADD_COMMENT: "App\\Notifications\\Tenant\\CaseAddCollaborationNotification",
  ADD_REPLY: "App\\Notifications\\Tenant\\CaseReplyCollaborationNotification",
  CANCEL_INVOICE: "App\\Notifications\\Tenant\\CancelInvoiceNotification",
  CREATE_INVOICE: "App\\Notifications\\Tenant\\CreateInvoiceNotification",
  CLIENT_INVOICE: "App\\Notifications\\Tenant\\IssueInvoiceNotification",
  SESSION_UPDATED: "App\\Notifications\\Tenant\\SessionUpdateNotification",
  SESSION_REMINDER: "App\\Notifications\\Tenant\\SessionReminderNotification",
  SESSION_PARTICIPANT:
    "App\\Notifications\\Tenant\\SessionParticipantNotification",
  SESSION_REMOVE_PARTICIPANT:
    "App\\Notifications\\Tenant\\SessionRemoveParticipantNotification",
  SESSION_REPORT_APPROVE: "App\\Notifications\\Tenant\\SessionReportApproveNotification",
  APPOINTMENT_ASSIGNEE: "App\\Notifications\\Tenant\\AppointmentAssigneeNotification",
  APPOINTMENT_REMOVE_ASSIGNEE: "App\\Notifications\\Tenant\\AppointmentRemoveAssigneeNotification",
  APPOINTMENT_UPDATED: "App\\Notifications\\Tenant\\AppointmentUpdateNotification",
  APPOINTMENT_DELETED: "App\\Notifications\\Tenant\\AppointmentDeleteNotification",
  APPOINTMENT_REMINDER: "App\\Notifications\\Tenant\\AppointmentReminderNotification",

  CONTRACT_ASSIGNEE:"App\\Notifications\\System\\ContractAssigneeNotification",
  CONTRACT_REMOVE_ASSIGNEE:"App\\Notifications\\System\\ContractRemoveAssigneeNotification",
  CONTRACT_UPDATE_TERMS:"App\\Notifications\\System\\ContractUpdateTermsNotification",
  CONTRACT_NEED_SIGN:"App\\Notifications\\System\\ContractNeedSignNotification"


};

export const arabicRegex = /^[\u0621-\u064A\u0660-\u06690-9 ]+$/;
export const notAllowArabicRegex =
  /^[a-zA-Z0-9` !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+$/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]*$/;
export const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
// export const alphaNumRegex = /^[a-zA-Z0-9][^._-`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]*$/; // accept english & arabic chars
export const alphaNumRegex = /^[A-Za-z0-9][A-Za-z0-9]*$/; // accept english chars only without whiet space
export const alphaNumWithSpaceRegex = /^[A-Za-z0-9][A-Za-z0-9 ]*$/; // accept english chars only with whiet space
export const alphaRegex = /^[A-Za-z][A-Za-z]*$/; // accept english letters only
export const allowNumbersOnly = /^[0-9]+$/;
export const alphaAR_EN_optionalNum = /(?=.*[A-Z])|(?=.*[a-z])|(?=.*[\u0621-\u064A\u0660-\u0669])([0-9]?)/; //accept english & arabic characters with specialChars and optional numbers

export const eventLocations = [
  {
    title: "أونلاين",
    id: "online",
  },
  {
    title: "في المقر",
    id: "onsite",
  },
];

export const remindersTime = [
  {
    title: "قبل",
    id: "before",
  },
  {
    title: "بعد",
    id: "after",
  },
];

export const remindersMethods = [
  {
    title: "ايميل",
    id: "email",
  },
  {
    title: "اشعار",
    id: "notification",
  },
  {
    title: "ايميل و اشعار",
    id: "emailAndNotification",
  },
];

export const intervalUnits = [
  {
    title: "يوم",
    id: "days",
  },
  {
    title: "ساعة",
    id: "hours",
  },
  {
    title: "دقيقة",
    id: "minutes",
  },
];

export const caseFilter = {
  category: [],
  court: [],
  circle: [],
  type: [],
  status: [],
  members: [],
  clients: [],
  start_date: null,
  end_date: null,
};

export const replyOptions = {
  send_to_members: "فريق العمل فقط",
  send_to_all: "العميل و فريق العمل",
};

export const empty_string = "";

export const mainColors = [
  { light: "#F3F2FD", dark: "#BB6BD9" }, //dark: "#897DE8"
  { light: "#F4F9FF", dark: "#F9CDA6" }, //light: "#FDF2EA" dark : "#ff974a"
  { light: "#EBFBF5", dark: "#3dd598" },
  { light: "#EAF1FE", dark: "#5990F4" }, //light: "#E5EFFF", dark: "#1d7ab4"
];

export const invoiceUnits = [
  {
    title: "وحدة",
    id: "وحدة",
  },
];
//keep arrangement -> //used in createEditInvoice form
export const invoiceStatus = [
  { title: "أُصدِرت", id: "issued" },
  { title: "قيد المراجعة", id: "inreview" },
  { title: "مستحقة الدفع", id: "payment_due" },
  { title: "مسودة", id: "draft" },
  { title: "تم الدفع", id: "paid" },
  { title: "ملغاه", id: "cancelled" },
];

export const speedContractStatus = [
  { title: "جديدة", id: 0 },
  { title: "بانتظار التوقيع", id: 1 },
  { title: "موقعة", id: 2 },
  { title: "مسندة", id: 3 },
  { title: "تحت التعديل", id: 4 },
  { title: "مرفوض", id: 5 },
  { title: "مسودة", id: 6 },
];

export const addFirmFormKind = {
  freeTrail: "freeTail",
  superAdmin: "superAdmin",
};
export const firmStatus = [
  { active: "active" },
  { expired: "expired" },
  { freeTrail: "freeTrail" },
];

export const expire__free_trial_msg = "free trial expired";
export const HIJRI = "hijri";
export const GEORGIAN = "georgian";

export const CASE_TABS = {
  TCase: 0,
  Party: 1,
  Member: 2,
  Contact: 3,
  Task: 4,
  Comment: 4, //comment in task
  Reply: 4, //Reply in task
  Session: 5,
  Verdict: 6,
  History: 7,
  Collaboration: 8,
  Attachment: 9,

}

export const metaTagsContent = [
  {
    id: 1, title: 'الصفحة الرئيسية',
    subTitle: 'نظام مستند لإدارة مكاتب المحاماة والشركات القانونية بكفاءة وأمان وفعالية',
    description: 'اكتشف مستند، الحل الأمثل لإدارة القضايا والجلسات بفعالية وأمان. ارفع كفاءة عملك وزد إنتاجيتك مع نظام مستند المتكامل لإدارة المكاتب القانونية.',
    url: '/'
  },
  {
    id: 2, title: 'من نحن',
    subTitle: 'من نحن | تعرف على فريق مستند ورؤيتنا لتقديم أفضل الحلول القانونية',
    description: 'تعرف على فريق مستند ورؤيتنا لتقديم أفضل حلول إدارة مكاتب المحاماة والشركات القانونية لتحقيق التحول الرقمي ودعم أهداف رؤية السعودية 2030.',
    url: '/من-نحن'
  },
  {
    id: 3, title: 'ما هو مستند',
    subTitle: 'ما هو مستند؟ | نظام متكامل لإدارة مكاتب المحاماة والشركات القانونية',
    description: 'اكتشف كيف يساعد مستند في إدارة القضايا والجلسات والوثائق بأمان وسهولة، وزيادة كفاءة وإنتاجية فريقك القانوني من خلال نظام متكامل آمن ومرن.',
    url: '/ما-هو-مستند'
  },
  {
    id: 4, title: 'الأسئلة الشائعة',
    subTitle: 'الأسئلة الشائعة | كل ما تحتاج معرفته عن نظام مستند لإدارة القضايا',
    description: 'اطلع على إجابات الأسئلة الشائعة حول نظام مستند وكيف يمكنه تحسين إدارة مكاتب المحاماة والشركات القانونية والتواصل مع العملاء من خلال ميزاته المتقدمة.',
    url: '/الأسئلة-الشائعة'
  },
  {
    id: 5, title: 'المدونة',
    subTitle: 'المدونة | مقالات ونصائح لتحسين إدارة المكاتب القانونية وزيادة الكفاءة',
    description: 'اقرأ أحدث المقالات والنصائح حول كيفية تحسين كفاءة وإنتاجية مكاتب المحاماة باستخدام نظام مستند، واكتشف الممارسات الفضلى في الإدارة القانونية.',
    url: '/المدونة'
  },
  {
    id: 6, title: 'تواصل معنا',
    subTitle: 'تواصل معنا | اتصل بفريق مستند للحصول على الدعم والمساعدة والاستفسارات',
    description: 'نحن هنا للمساعدة! تواصل مع فريق مستند للحصول على دعم فني أو استفسارات حول منتجاتنا وخدماتنا وكيفية تحسين إدارة مكاتب المحاماة بكفاءة وأمان.',
    url: '/تواصل-معنا'
  },
  {
    id: 7, title: 'سياسة الخصوصية',
    subTitle: 'سياسة الخصوصية | حماية بياناتك مع نظام مستند لضمان الأمان والسرية',
    description: 'اقرأ سياسة الخصوصية لدينا لمعرفة كيف نحمي بياناتك ونضمن السرية والأمان عند استخدام نظام مستند لإدارة مكاتب المحاماة والشركات القانونية.',
    url: '/سياسة-الخصوصية'
  },
  {
    id: 8, title: 'الشروط والأحكام',
    subTitle: 'الشروط والأحكام | استخدام مستند لإدارة مكاتب المحاماة والشركات القانونية',
    description: 'اطلع على الشروط والأحكام الخاصة باستخدام نظام مستند لضمان أفضل تجربة استخدام لمكاتب المحاماة والشركات القانونية، ومعرفة الالتزامات والحقوق.',
    url: '/الشروط-والأحكام'
  },
  {
    id: 9, title: 'التجربة المجانية',
    subTitle: 'التجربة المجانية | جرب نظام مستند مجانًا لتحسين إدارة مكاتب المحاماة',
    description: 'احصل على تجربة مجانية لنظام مستند واكتشف كيف يمكنه تحسين إدارة مكاتب المحاماة وزيادة كفاءتها من خلال ميزاته المتقدمة وإدارته المتكاملة.',
    url: '/التجربة-المجانية'
  },
  {
    id: 10, title: 'مدونة | دليل أفضل نظام إدارة القضايا',
    subTitle: 'الدليل الشامل لأفضل نظام إدارة القضايا للمكاتب القانونية',
    description: 'اكتشف أفضل نظام إدارة القضايا وكيفية استخدامها وفوائدها، حيث نقدم إليك الدليل الشامل حول نظام إدارة القضايا "مستند" لتحسين كفاءة مكتبك القانوني.',
    url: '/دليل-أفضل-نظام-إدارة-القضايا'
  },
  {
    id: 11, title: 'إدارة العملاء بكفاءة دليل شامل للمحامين وأصحاب المكاتب',
    subTitle: 'أفضل الممارسات لنظام إدارة العملاء في المكاتب القانونية وشركات المحاماة',
    description: 'استكشف كيف يمكن لنظام إدارة العملاء الذكي في المكاتب القانونية أن يسهم في تحسين كفاءتها التشغيلية وتعزيز تجربة عملائها من خلال الحلول المتقدمة والفعّالة.',
    url: '/إدارة-العملاء-بكفاءة-دليل-شامل-للمحامين-وأصحاب-المكاتب'
  },

  {
    id: 12, title: 'كيف يمكن للأتمتة أن تحسن ممارستك القانونية',
    subTitle: 'أتمتة قانونية احترافية لتحسين كفاءة المكاتب القانونية',
    description: 'اكتشف كيف يمكن للأتمتة أن تطور ممارستك القانونية بفضل تحسين الكفاءة والإنتاجية وتقليل الأخطاء وتوفير التكاليف.',
    url: '/فوائد-أتمتة-ممارستك-القانونية-مع-مستند'
  },
  {
    id: 13, title: 'اكتشف فوائد الأرشفة السحابية وكيف تساهم في توفير التكاليف وتحسين الكفاءة',
    subTitle: 'اكتشف فوائد الأرشفة السحابية وكيف تساهم في توفير التكاليف وتحسين الكفاءة',
    description:"تعرف على أفضل الحلول السحابية لأرشفة المستندات القانونية في برنامج مستند وكيفية توفير التكاليف وزيادة الكفاءة في المكاتب القانونية باستخدام تقنيات الأرشفة السحابية",
    url: '/فوائد-الحلول-السحابية-في-أرشفة-المستندات-القانونية'
  },
]

export const createContractWizardFormOrdering = [
  {id: 1, step: 'contractType'},
  {id: 2, step: 'contractParties'},
  {id: 3, step: 'contractProperty'},
  {id: 4, step: 'contractPayment'},
  {id: 5, step: 'contractDates'},
  {id: 6, step: 'contractTerms'},
]
